<template>
  <img v-on:load="onload" :class="classImg" :src="src"/>
</template>

<script>

export default {
  name: 'Img',
  props: ['doc'],
  components: {
  },
  data () {
    return {
      src: '',
      observer: null,
      image_loaded: false,
      image_intersected: false
    }
  },
  created () {
    // this.onload()
  },
  mounted: function () {
    this.initObserver()
  },
  destroyed () {
    this.observer.disconnect()
  },
  computed: {
    classImg () {
      return this.image_loaded && this.image_intersected ? 'image_img show' : 'image_img'
    }
  },
  methods: {
    onload () {
      this.image_loaded = true
    },
    initObserver () {
      this.observer = new IntersectionObserver(entries => {
        const image = entries[0]
        if (image.isIntersecting) {
          console.log('image is intersecting')
          this.src = this.doc
          this.image_intersected = true
          this.observer.disconnect()
        }
      }, {
        rootMargin: '0px'
      })
      this.observer.observe(this.$el)
    }
  }
}
</script>

<style lang="scss" scoped>

.image_img {
  width: 100%;
  display: block;
  opacity: 0;
  transition: opacity 1s linear 0s;
  &.show {
    opacity: 1;
  }
}

</style>
